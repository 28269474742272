/* @import url('http://fonts.cdnfonts.com/css/uber-move-2'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'Outfit-Medium';
  src: url('./assets/fonts/Outfit-Medium.ttf');
}

@font-face {
  font-family: 'Outfit-Regular';
  src: url('./assets/fonts/Outfit-Regular.ttf');
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Outfit';
}
body {
  margin: 0;
}
/* *::-webkit-scrollbar {
  display: none;
} */
select:required:invalid {
  color: #aab3bf !important;
}

option[value=''][disabled] {
  display: none;
}

.form-input > input {
  border: none !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wave {
  width: 10px;
  height: 10px;
  background: linear-gradient(20deg, #047dde, #fff);
  margin: 1.5px;
  animation: wave 3s linear infinite;
  border-radius: 10px;
}

.grow {
  animation: grow 3s linear infinite;
}

.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}
.settlement-service-header button {
  background-color: unset !important;
}
@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes grow {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
